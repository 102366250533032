<template>
  <div class="empty">
    <van-empty class="v-empty" :image="offLine ? 'network' : defaultImg">
      <div class="desc" v-show="!offLine">
        暂没有推荐学习的单词<br />请调整目标或者添加新单词到单词本
      </div>
      <div class="desc" v-show="offLine">网络出错啦！</div>
    </van-empty>
    <div class="empty-back">
      <v-btn class="ma-2" outlined color="#ccc3a5" @click="refresh">
        重新获取
      </v-btn>
    </div>
  </div>
</template>

<script>
import { buttonVibrate } from "@/mixin/mixin";

export default {
  name: "errorPage",
  mixins: [buttonVibrate],
  props: {
    offLine: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    searchImg: require("@/assets/uiimg/empty-image-search.png"),
    defaultImg: require("@/assets/uiimg/empty-image-default.png"),
  }),
  methods: {
    refresh() {
      this.zzzShort();
      this.$emit("refresh");
    },
    goBack() {
      this.$router.back();
    },
    doRefresh() {
      this.$emit("doRefresh");
    },
  },
};
</script>

<style lang="scss" scoped>
.empty {
  margin: 0 auto;
  min-height: 8rem;
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 0.8rem;
  color: #9f9f9f;
  text-align: center;
  background-color: #ffffff00;
  //> div {
  //  margin: 0.6rem;
  //}
  .v-empty {
    margin-top: -5rem;

    .desc {
      margin: 0.6rem;
    }
  }

  .empty-back {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 0;
  }
}
</style>
