import { render, staticRenderFns } from "./errorPage.vue?vue&type=template&id=b05f131e&scoped=true&"
import script from "./errorPage.vue?vue&type=script&lang=js&"
export * from "./errorPage.vue?vue&type=script&lang=js&"
import style0 from "./errorPage.vue?vue&type=style&index=0&id=b05f131e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.9.8_679359cdb69c218f2f8f476b2ba08796/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b05f131e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/.pnpm/vuetify-loader@1.7.3_cfe6260f4a77386d492c4e564aa08ca3/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})
